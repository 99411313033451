import React, { useEffect, useState, useRef } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './Footer.scss';

const FooterComponent = ( { pageData, footerData, socialData } ) => {
  // const [ menuData, setMenuData ] = useState( [] );
  const [ socialChannelData, setSocialChannelData ] = useState( [] );
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  useEffect( () => {
    const menu = [];
    footerData.hygienicmenu.map( link => {
      pageData.map( page => {
        if ( link.pages.id === page.node.prismicId )
          menu.push( page.node.data );

      } );
    } );
    // setMenuData( menu );
  }, [ pageData, footerData ] );

  useEffect( () => {
    const menu = [];
    footerData.socialchannels.map( link => {
      socialData.map( social => {
        if ( link.socialchannel.id === social.node.prismicId )
          menu.push( social.node.data );

      } );
    } );
    setSocialChannelData( menu );
  }, [ socialData, footerData ] );

  return (
    <footer
      className={classnames(
        "footer",
        onScreen?.isIntersecting ? "visible" : ""
      )}
      ref={wrapperRef}
    >
      {footerData?.backgroundvideo?.raw.kind === "document" &&
        footerData?.backgroundvideo?.raw.url && (
          <div
            className="footer-background"
            dangerouslySetInnerHTML={{
              __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${footerData.backgroundvideo.raw.url}"
          />
        `,
            }}
          ></div>
        )}
      <div className="flex-wrapper">
        <div className="footer-wrapper">
          <div className="footer-content">
            <p className="footer-title">{footerData.title}</p>
            <Link to="/" className="footer-logo">
              <img
                alt={footerData.footerlogoalt || "footer logo"}
                src={footerData.footerlogo.url}
              />
            </Link>
            <ul className="footer-data">
              <li>
                <span>email our team</span>
                <a
                  href={"mailto:" + footerData.firstemailaddress}
                  target="_blank"
                  rel="noreferrer"
                >
                  {footerData.firstemailaddress}
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/jobs/search/?currentJobId=3990417961&f_C=1766772&geoId=92000000&origin=COMPANY_PAGE_JOBS_CLUSTER_EXPANSION&originToLandingJobPostings=3990417961%2C3988864124%2C3990420359"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{footerData.secondemaillabel}</span>
                </a>
                <a
                  href={"mailto:" + footerData.secondemailaddress}
                  target="_blank"
                  rel="noreferrer"
                >
                  {footerData.secondemailaddress}
                </a>
              </li>
            </ul>
            <div className="social-icons">
              <p>follow us</p>
              <ul>
                {socialChannelData.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <img
                          src={item.icon.url}
                          alt={item.icon.alt || "social icon"}
                        />
                        <img
                          src={item.iconhover.url}
                          className="hover-img"
                          alt={item.iconhover.alt || "social icon hover"}
                        />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="footer-menu-bottom">
            <ul className="footer-menu">
              {/* {
                menuData.map( ( item, index ) => {
                  return (
                    <li key={ index }>
                      <Link to={ "/" + item.slug }>
                        <span className="page-title">{ item.name }</span>
                      </Link>
                    </li>
                  );
                } )
              } */}
              <li className="copyright">{footerData.copyright}</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export const Footer = ( props ) => {
  return (
    <StaticQuery
      query={ graphql`
        query footerQuery {
          allPrismicPages {
            edges {
              node {
                prismicId
                data {
                  name
                  slug
                  fullname
                }
              }
            }
          }
          allPrismicSocialChannel {
            edges {
              node {
                prismicId
                data {
                  link
                  icon {
                    alt
                    url
                  }
                  iconhover {
                    alt
                    url
                  }
                }
              }
            }
          }
          allPrismicFooter {
            edges {
              node {
                data {
                  title
                  footerlogo {
                    url
                  }
                  footerlogoalt
                  backgroundvideo {
                    raw
                  }
                  firstemaillabel
                  firstemailaddress
                  secondemaillabel
                  secondemailaddress
                  copyright
                  hygienicmenu {
                    pages {
                      id
                    }
                  }
                  socialchannels {
                    socialchannel {
                      id
                    }
                  }
                }
              }
            }
          }
        }

      `}
      render={ data => <FooterComponent socialData={ data.allPrismicSocialChannel.edges } pageData={ data.allPrismicPages.edges } footerData={ data.allPrismicFooter.edges[ 0 ].node.data } { ...props } /> }
    />
  );
};